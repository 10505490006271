/* tslint:disable */
/* eslint-disable */
/**
 * APIFlask
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface Feedback
 */
export interface Feedback {
    /**
     * 
     * @type {string}
     * @memberof Feedback
     */
    queryText: string;
    /**
     * 
     * @type {string}
     * @memberof Feedback
     */
    matchText: string;
    /**
     * 
     * @type {string}
     * @memberof Feedback
     */
    matchCode: string;
    /**
     * 
     * @type {number}
     * @memberof Feedback
     */
    label: number;
}

export function FeedbackFromJSON(json: any): Feedback {
    return FeedbackFromJSONTyped(json, false);
}

export function FeedbackFromJSONTyped(json: any, ignoreDiscriminator: boolean): Feedback {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'queryText': json['query_text'],
        'matchText': json['match_text'],
        'matchCode': json['match_code'],
        'label': json['label'],
    };
}

export function FeedbackToJSON(value?: Feedback | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'query_text': value.queryText,
        'match_text': value.matchText,
        'match_code': value.matchCode,
        'label': value.label,
    };
}


