/* tslint:disable */
/* eslint-disable */
/**
 * APIFlask
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    Course,
    CourseFromJSON,
    CourseToJSON,
    HTTPError,
    HTTPErrorFromJSON,
    HTTPErrorToJSON,
    ValidationError,
    ValidationErrorFromJSON,
    ValidationErrorToJSON,
} from '../models';

export interface MatchCourseCodeGetRequest {
    courseCode: string;
}

export interface MatchTextGetRequest {
    queryText?: string;
}

/**
 * 
 */
export class MatchApi extends runtime.BaseAPI {

    /**
     * Match Code
     */
    async matchCourseCodeGetRaw(requestParameters: MatchCourseCodeGetRequest): Promise<runtime.ApiResponse<Array<Course>>> {
        if (requestParameters.courseCode === null || requestParameters.courseCode === undefined) {
            throw new runtime.RequiredError('courseCode','Required parameter requestParameters.courseCode was null or undefined when calling matchCourseCodeGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/match/{course_code}`.replace(`{${"course_code"}}`, encodeURIComponent(String(requestParameters.courseCode))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(CourseFromJSON));
    }

    /**
     * Match Code
     */
    async matchCourseCodeGet(requestParameters: MatchCourseCodeGetRequest): Promise<Array<Course>> {
        const response = await this.matchCourseCodeGetRaw(requestParameters);
        return await response.value();
    }

    /**
     * Match Text
     */
    async matchTextGetRaw(requestParameters: MatchTextGetRequest): Promise<runtime.ApiResponse<Array<Course>>> {
        const queryParameters: any = {};

        if (requestParameters.queryText !== undefined) {
            queryParameters['query_text'] = requestParameters.queryText;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/match/text`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(CourseFromJSON));
    }

    /**
     * Match Text
     */
    async matchTextGet(requestParameters: MatchTextGetRequest): Promise<Array<Course>> {
        const response = await this.matchTextGetRaw(requestParameters);
        return await response.value();
    }

}
