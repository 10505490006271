/* tslint:disable */
/* eslint-disable */
/**
 * APIFlask
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    Feedback,
    FeedbackFromJSON,
    FeedbackToJSON,
    ValidationError,
    ValidationErrorFromJSON,
    ValidationErrorToJSON,
} from '../models';

export interface FeedbackPostRequest {
    feedback?: Feedback;
}

/**
 * 
 */
export class FeedbackApi extends runtime.BaseAPI {

    /**
     * Returns: feedback: feedback
     * Saves feedback
     */
    async feedbackPostRaw(requestParameters: FeedbackPostRequest): Promise<runtime.ApiResponse<Feedback>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/feedback`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: FeedbackToJSON(requestParameters.feedback),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => FeedbackFromJSON(jsonValue));
    }

    /**
     * Returns: feedback: feedback
     * Saves feedback
     */
    async feedbackPost(requestParameters: FeedbackPostRequest): Promise<Feedback> {
        const response = await this.feedbackPostRaw(requestParameters);
        return await response.value();
    }

}
